import { DataAccess } from "../../dataaccess/data.access";
import { Cache, Invalidate } from "../../decorators/cache.decorator";
import { Timeout } from "../../decorators/timeout.decorator";
import { BpeUtil } from "./bpe.util";
import { S25Util } from "../../util/s25-util";
import { EmailClass, EmailReport, EmailService, TodoClass } from "../../services/email.service";
import { ExecutionEnvironment } from "../../services/execution.enviroment";
import { TodoService } from "../../services/task/todo.service";
import { FlsService } from "../../services/fls.service";
import { Proto } from "../../pojo/Proto";
import { EventData, EventIncludeOption, EventService } from "../../services/event.service";
import { PricingService } from "../../services/pricing.service";
import { Adjustment, LineItem, Total } from "../../pojo/Pricing";
import { TaskService } from "../../services/task/task.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { AccessLevels, Fls, hasFullFls, hasReadFls } from "../../pojo/Fls";
import { UserprefService } from "../../services/userpref.service";
import { BpeScenarioTypes, BpeVarsI } from "./BpeI";
import { DocumentListItem, DocumentService } from "../../services/document.service";
import { Rules } from "../s25-rule-tree/s25.rule.const";
import { TelemetryService } from "../../services/telemetry.service";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";
import { ScheduledEmailService } from "../../services/scheduled.email.service";
import NumericalBoolean = Proto.NumericalBoolean;
import NumericalString = Proto.NumericalString;
import MdTag = MasterDefTag.MdTag;
import { Item } from "../../pojo/Item";
import { OrganizationService } from "../../services/organization.service";
import { DefinitelyArray } from "../../pojo/Util";
import { Report } from "../../pojo/Report";

export class BpeService {
    @Timeout
    @Cache({ immutable: true, targetName: "BpeService" })
    public static getFullTemplates() {
        return DataAccess.get(DataAccess.injectCaller("/bpe/templates.json", "BpeService.getFullTemplates")).then(
            function (data: EmailFullTemplatesResponse) {
                let templates = data?.root?.templates || [];
                templates.forEach((template) => {
                    template.tags = S25Util.array.forceArray(template.tags);
                    //ANG-4975 we found 'Confirmation' instead of 'confirmation' once. Force lowercase here instead of tracking down every comparison
                    //also normalizes a single report eg. 423 to return as a string instead of a number
                    template.reports = S25Util.toStr(template.reports)?.toLowerCase();
                });
                return templates;
            },
        );
    }

    /**
     * @param getAll - if true will override tag base security
     * @param omitReadOnly
     */
    @Timeout
    @Cache({ immutable: true, targetName: "BpeService" })
    public static async getManualTemplates(getAll = false, omitReadOnly?: boolean) {
        let data: EmailTemplate[];
        if (getAll) {
            data = await BpeService.getFullTemplates();
            data = data.filter(
                (template) =>
                    S25Util.toBool(template.isManual) &&
                    (!omitReadOnly || MasterDefinitionTagsService.isItemReadOnly(template.tags)),
            );
        } else {
            //using type_name=manual will only return user can see - configured via system tags
            const resp = await DataAccess.get(
                DataAccess.injectCaller("/bpe/templates.json?type_name=manual", "BpeService.getManualTemplates"),
            );
            data = resp?.root?.templates || [];
        }

        return data;
    }

    @Timeout
    @Cache({ immutable: true, targetName: "BpeService" })
    public static getFullTodoTemplates() {
        return DataAccess.get(
            DataAccess.injectCaller("/bpe/templates.json?type_name=todo", "BpeService.getFullToDoTemplates"),
        ).then(function (data: ToDoFullTemplatesResponse) {
            return (data && data.root && data.root.templates) || [];
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "BpeService" })
    public static getFullScenarios() {
        return DataAccess.get(DataAccess.injectCaller("/bpe/scenarios.json", "BpeService.getFullScenarios")).then(
            function (data: EmailFullScenariosResponse) {
                return (data && data.root && data.root.scenarios) || [];
            },
        );
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getManualTemplates" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTemplates" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTodoTemplates" })
    public static putTemplate(
        itemId: number,
        itemName: string,
        isManual: boolean,
        mode: any,
        to: string[],
        from?: string,
        cc?: string[],
        bcc?: string[],
        reports?: string[],
        subject?: string,
        body?: string,
        code?: any,
    ) {
        return DataAccess.put(DataAccess.injectCaller("/bpe/template.json", "BpeService.putTemplate"), {
            root: {
                itemId: itemId,
                itemName: itemName,
                isManual: +isManual, //convert boolean to number
                mode: mode,
                to: to,
                from: from,
                cc: cc,
                bcc: bcc,
                reports: reports,
                subject: subject,
                body: body,
                code: code,
            },
        });
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTemplates" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTodoTemplates" })
    public static putTemplateByTemplate(template: any) {
        if (template.isToDoTemplate && template.isToDoTemplate === 1) {
            const { itemId, itemName, mode, taskName, assignBy, assignTo, comment, dueDate, code } = template;
            return DataAccess.put(
                DataAccess.injectCaller("/bpe/template.json?type_name=todo", "BpeService.putTemplate"),
                {
                    root: {
                        itemId,
                        itemName,
                        mode,
                        taskName,
                        assignBy,
                        assignTo,
                        comment,
                        dueDate,
                        code,
                    },
                },
            );
        } else {
            const { itemId, itemName, isManual, iCalFile, mode, to, from, cc, bcc, reports, subject, body, code } =
                template;
            return DataAccess.put(DataAccess.injectCaller("/bpe/template.json", "BpeService.putTemplate"), {
                root: {
                    itemId,
                    itemName,
                    isManual,
                    iCalFile,
                    mode,
                    to,
                    from,
                    cc,
                    bcc,
                    reports,
                    subject,
                    body,
                    code,
                },
            });
        }
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    public static putScenario(
        itemId: string,
        itemName: string,
        mode: string,
        isActive: boolean,
        inclLocPref: boolean,
        inclResPref: boolean,
        onAction: any,
        onTaskAction: any,
        onEventFormAction: any,
        onExpressAction: any,
        onEventStateChangeAction: any,
        onTodoAction: boolean,
        eventStates: any,
        preEventStates: any,
        eventTypes: any,
        locations: any,
        resources: any,
        organizations: any,
        attributes: any,
        requirements: any,
        securityGroups: any,
        code: any,
        templates: any,
    ) {
        let data: any = {
            root: {
                itemId: itemId || "",
                itemName: itemName || "",
                mode: mode,
                isActive: +isActive, //convert boolean to int
                inclLocPref: +inclLocPref,
                inclResPref: +inclResPref,
                onAction: onAction,
                onTaskAction: +onTaskAction,
                onEventFormAction: +onEventFormAction,
                onExpressAction: +onExpressAction,
                onEventStateChangeAction: +onEventStateChangeAction,
                onTodoAction: +onTodoAction,
                eventStates: eventStates,
                preEventStates: preEventStates,
                eventTypes: eventTypes,
                locations: locations,
                resources: resources,
                organizations: organizations,
                attributes: attributes,
                requirements: requirements,
                securityGroups: securityGroups,
                code: code,
                templates: templates,
            },
        };

        return DataAccess.put(DataAccess.injectCaller("/bpe/scenario.json", "BpeService.putScenario"), data);
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    public static putScenarioByScenario(scenario: EmailScenario) {
        if (scenario.isScheduled) {
            TelemetryService.sendWithSub("EventSaveEmail", "Scenario", "AddSchedule");
        }
        return DataAccess.put(DataAccess.injectCaller("/bpe/scenario.json", "BpeService.putScenarioByScenario"), {
            root: scenario,
        });
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getManualTemplates" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTemplates" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    @Invalidate({ serviceName: "BpeService", methodName: "getFullTodoTemplates" })
    public static deleteTemplate(itemId: number, type?: any) {
        if (type && type === "todo") {
            return DataAccess.delete(
                DataAccess.injectCaller(
                    "/bpe/template.json?itemId=" + itemId + "&type_name=todo",
                    "BpeService.deleteTemplate",
                ),
            );
        } else {
            return DataAccess.delete(
                DataAccess.injectCaller("/bpe/template.json?itemId=" + itemId, "BpeService.deleteTemplate"),
            );
        }
    }

    @Timeout
    @Invalidate({ serviceName: "BpeService", methodName: "getFullScenarios" })
    public static deleteScenario(itemId: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/bpe/scenario.json?itemId=" + itemId, "BpeService.deleteScenario"),
        );
    }

    @Timeout
    public static async getInterpolatedString(
        eventData: any,
        profileId: number,
        preEventData: any,
        str: any,
        skipNestedStr?: boolean,
        source?: "document" | "scenario" | "template",
    ) {
        str = S25Util.toStr(str);
        return S25Util.all({
            vars: BpeUtil.getVariables(eventData, profileId, preEventData, null),
        }).then(function (resp) {
            return {
                interpolatedStr: BpeUtil.interpolateString(resp.vars, str, skipNestedStr, null, source),
                vars: resp.vars,
            };
        });
    }

    @Timeout
    //Pre event data is optional for use with manual templates
    public static getEmailObjectFromTemplate(eventData: any, profileId: number, template: any, preEventData?: any) {
        let templateCode = template.mode === "code" ? template.code : BpeUtil.templateToCode(template);
        return BpeService.getInterpolatedString(eventData, profileId, preEventData, `{${templateCode}}`).then(
            function (resp) {
                try {
                    return ExecutionEnvironment.run(resp.interpolatedStr, {
                        vars: resp.vars,
                        eventData: eventData,
                        util: BpeUtil.util,
                    });
                } catch (e) {
                    console.error(S25Util.errorText(e));
                    return null;
                }
            },
        );
    }

    public static async runScenariosAfterChange(data: {
        preEventData?: EventData;
        postEventDataPromise: Promise<EventData>;
        source: BpeScenarioTypes;
        profileId?: number;
        // Validate against postEventData whether we really want to run scenarios
        validate?: (preEventData: EventData, postEventData: EventData) => boolean;
        noChange?: boolean;
    }) {
        const { preEventData, postEventDataPromise, profileId, source, validate, noChange } = data;

        // Fetch additional data concurrently with the eventData promise (if possible)
        const additionalData = BpeService.getAdditionalEventData({ preEventData, postEventDataPromise });

        const [postEventData] = await Promise.all([postEventDataPromise, additionalData]);
        if (!postEventData) return;
        if (validate && !validate(preEventData, postEventData)) return;

        return BpeService.runScenarios({ postEventData, profileId, preEventData, source, noChange });
    }

    public static async getDataForOrganizations(orgs: DefinitelyArray<EventData["organization"]>) {
        if (!orgs?.length) return;

        const orgById = new Map(orgs.map((org) => [org.organization_id, org]));
        const ids = orgs.map((org) => org.organization_id);

        const orgData = await OrganizationService.getOrganizationsById(ids, ["contacts", "address"]);

        for (const org of orgData) {
            const eventOrg = orgById.get(Number(org.organization_id));
            if (!eventOrg) continue;
            eventOrg.contact = org.contact;
            eventOrg.address = org.address;
        }
    }

    // Get organization addresses and contacts from preEventData, then marshall it to postEventData
    // If postEventData has organizations which preEventData does not have, fetch those too, afterward
    public static async getAdditionalEventDataOrganizations(data: {
        preEventData?: EventData;
        postEventDataPromise: Promise<EventData>;
    }) {
        const { preEventData, postEventDataPromise } = data;

        const preOrgs = S25Util.array.forceArray(preEventData?.organization);
        const preOrgsById = new Map(preOrgs.map((org) => [org.organization_id, org]));
        await BpeService.getDataForOrganizations(preOrgs);

        // Apply data from preEvent orgs to postEvent orgs
        const postEventData = await postEventDataPromise;
        if (!postEventData) return;
        const postOrgs = S25Util.array.forceArray(postEventData.organization);
        const missingOrgs: DefinitelyArray<EventData["organization"]> = [];
        for (const org of postOrgs) {
            const preOrg = preOrgsById.get(org.organization_id);
            if (!preOrg) missingOrgs.push(org);
            else {
                org.contact = preOrg.contact;
                org.address = preOrg.address;
            }
        }

        // Fetch any missing data
        await BpeService.getDataForOrganizations(missingOrgs);
    }

    // Use preEventData to get scheduled emails and attach to both pre- and postEvent data
    public static async getAdditionalEventDataScheduledEvents(data: {
        preEventData?: EventData;
        postEventDataPromise: Promise<EventData>;
    }) {
        const { preEventData, postEventDataPromise } = data;
        if (!preEventData) return; // If there is no preEventData there can be no scheduled emails

        const eventId = Number(preEventData.event_id);
        const [emails, postEventData] = await Promise.all([
            ScheduledEmailService.getScheduledEmails(Item.Ids.Event, eventId),
            postEventDataPromise,
        ]);
        preEventData.scheduledEmails = emails;
        if (postEventData) postEventData.scheduledEmails = emails;
    }

    public static async getAdditionalEventData(data: {
        preEventData?: EventData;
        postEventDataPromise: Promise<EventData>;
    }) {
        await Promise.all([
            BpeService.getAdditionalEventDataOrganizations(data),
            BpeService.getAdditionalEventDataScheduledEvents(data),
        ]);
    }

    public static async runScenarios(data: {
        postEventData: EventData;
        profileId?: number;
        preEventData?: EventData;
        source: BpeScenarioTypes;
        noChange?: boolean;
    }) {
        const triggered = await BpeService.getTriggeredScenarios(data);
        if (!triggered) return;
        const { variables, todo, email, schedule } = triggered;

        BpeService.sendEmails(email, variables, data.postEventData);
        BpeService.scheduleEmails(schedule, variables, data.postEventData);
        BpeService.bpeCreateToDo(todo, variables, data.postEventData);
    }

    /**
     * Determines which scenarios have had their criteria met, so they are triggered.
     * Returns - The vars object created from Pre/Post save eventData and a list of returned scenarios
     * @param data
     */
    @Timeout
    public static async getTriggeredScenarios(data: {
        postEventData: EventData;
        profileId?: number;
        preEventData?: EventData;
        source: BpeScenarioTypes;
        noChange?: boolean;
    }): Promise<{ variables: BpeVarsI; todo: EmailScenario[]; email: EmailScenario[]; schedule: EmailScenario[] }> {
        const { preEventData, postEventData, source, profileId } = data;
        let getFullTaskData = false;
        let todoId;

        //Only care about cancel request todos, other todos will get email from the admin/db templates only
        if ("cancelTodos" === source) {
            const changedTodos = TodoService.findChangedTodos(findTodos(preEventData), findTodos(postEventData));
            if (changedTodos.new.length > 0 || changedTodos.changed.length > 0) {
                todoId = changedTodos.new.length ? changedTodos.new[0] : changedTodos.changed[0];
                getFullTaskData = true;
            } else {
                return;
            }
        }

        const [scenarios, variables] = await Promise.all([
            BpeService.getFullScenarios(),
            BpeUtil.getVariables(postEventData, profileId, preEventData, source, getFullTaskData, todoId),
        ]);

        const triggeredScenarios: Set<number> = new Set();
        const todo: EmailScenario[] = [];
        const email: EmailScenario[] = [];
        const schedule: EmailScenario[] = [];
        for (const scenario of scenarios) {
            const triggered = BpeService.executeScenario({ scenario, variables, eventData: postEventData });
            if (!triggered) continue;

            triggeredScenarios.add(scenario.itemId);
            if (scenario.createToDo) todo.push(scenario);
            // Note that a scenario can BOTH create a task AND send emails, so the below should not be an else if
            if (scenario.isScheduled) schedule.push(scenario);
            else email.push(scenario);

            console.log("scenario will run", scenario.itemName);
        }

        // We need to update any scheduled emails, even if the scenario did not trigger
        // If no change was made (e.g. user acted on an FYI task), we don't need to update any scheduled emails
        if (!data.noChange) {
            const scenariosById = new Map(scenarios.map((s) => [s.itemId, s]));
            for (const scheduledEmail of postEventData.scheduledEmails ?? []) {
                const id = scheduledEmail.scenarioId;
                if (!id) continue;
                const triggered = triggeredScenarios.has(id);
                if (!triggered) schedule.push(scenariosById.get(id));
            }
        }

        //This comment is to make grabbing test scenarios more simple
        // console.log("mockData", {
        //     source: source,
        //     profileId: profileId,
        //     eventData: eventData,
        //     preEventData: preEventData,
        //     mockedScenarios:
        //         resp?.scenarios?.length > 0 &&
        //         resp.scenarios.filter((sec: any) => {
        //             return sec?.isActive === 1;
        //         }),
        //     results: retVal,
        // });

        return {
            variables,
            todo,
            email,
            schedule,
        };
    }

    public static executeScenario(data: { scenario: EmailScenario; variables: BpeVarsI; eventData: any }): boolean {
        const { scenario, variables, eventData } = data;
        if (!S25Util.toBool(scenario.isActive)) return false;

        const scenarioCode = /code/i.test(scenario.mode) ? scenario.code : BpeUtil.scenarioToCode(scenario);
        const finalScenarioCode = `{${BpeUtil.interpolateString(variables, scenarioCode)}}`; // Wrapped in {} to keep variables out of global scope
        variables.scenario = scenario.itemName;
        try {
            return ExecutionEnvironment.run(finalScenarioCode, {
                vars: variables,
                eventData,
                util: BpeUtil.util,
            });
        } catch (e) {
            console.error(S25Util.errorText(e));
            return false;
        }
    }

    /**
     * Currently an alias for BpeService.sendEmails
     */
    public static async scheduleEmails(scenarios: EmailScenario[], variables: BpeVarsI, eventData: any) {
        return BpeService.sendEmails(scenarios, variables, eventData);
    }

    //source: "event-form", "express", "event-state-change", "tasks", "cancelTodos"
    @Timeout
    public static async sendEmails(scenarios: EmailScenario[], variables: BpeVarsI, eventData: any) {
        if (!scenarios.length) return;
        let eventId = parseInt(S25Util.propertyGetVal(eventData, "event_id"));

        const [fls, sessionId, reports, contracts] = await Promise.all([
            FlsService.getFls(),
            UserprefService.getSessionId(),
            EventService.getEventTypeReports(),
            DocumentService.getDocumentList("event"),
        ]);

        const sendData: BpeSendEmailData = { eventData, eventId, variables, fls, sessionId, reports, contracts };
        for (const scenario of scenarios || []) {
            for (const template of scenario?.templates || []) {
                BpeService.sendTemplate({ ...sendData, template, scenario }).catch((err) =>
                    console.error(S25Util.errorText(err)),
                );
            }
        }
    }

    public static async sendTemplate(
        data: BpeSendEmailData & {
            template: EmailTemplate;
            scenario: EmailScenario;
        },
    ) {
        const { template, scenario, variables, eventData, eventId, sessionId, reports, contracts, fls } = data;
        const templateCode = template.mode === "code" ? template.code : BpeUtil.templateToCode(template);
        variables.scenario = scenario.itemName;
        variables.template = template.itemName;
        const finalTemplateCode = `{${BpeUtil.interpolateString(variables, templateCode)}}`;
        const email = BpeService.executeEmailTemplate(finalTemplateCode, variables, eventData);
        let webServiceFiles: string[];
        if (parseInt(email.iCalFile) === 1) {
            webServiceFiles = [DataAccess.getUrl(`/event.ics?event_id=${eventId}&session_id=${sessionId}`)];
        }

        await BpeService.populateDMReports({ reports, contracts, email, eventId });

        const reportRights = hasReadFls(fls.REP_LIST);
        return EmailService.sendEmailFromClass(
            email,
            eventId,
            1,
            reportRights,
            webServiceFiles,
            template.itemId,
            scenario,
        );
    }

    public static async getEventBillItems(eventId: number) {
        const pricing = await PricingService.getStandardPricingForEventIncludeRateInfo([eventId]);
        const { data, expandedInfo } = pricing;
        const { lineItems, totals, adjustments } = data.items[0].billing;

        const rateGroups: Record<number, string> = {};
        const rateSchedules: Record<number, string> = {};
        for (let group of expandedInfo.rateGroups) rateGroups[group.rateGroup_id] = group.rateGroupName;
        for (let schedule of expandedInfo.rateSchedules) rateSchedules[schedule.rateId] = schedule.rateName;

        // Line Items
        const billItems: BpeBillItem[] = (lineItems || [])
            .map((item) => ({
                ...item,
                // Add rate group and rate schedule names
                rateGroupName: rateGroups[item.rateGroupId] || "",
                rateScheduleName: rateSchedules[item.rateId] || "",
            }))
            .reverse(); // Reverse so that spaces come first

        // Subtotal
        billItems.push({
            itemName: "Subtotal",
            listPrice: totals.occurrenceListPrice,
            adjustmentAmt: totals.taxableAmount - totals.occurrenceListPrice,
            price: totals.taxableAmount,
            tax: totals.tax,
            total: totals.taxableAmount + totals.tax,
        });

        // End Adjustments
        Array.prototype.push.apply(
            billItems,
            (adjustments || []).map((adj) => ({ itemName: "Adjustment", ...adj })),
        );

        // Grand Total
        billItems.push({
            itemName: "Total",
            listPrice: totals.occurrenceListPrice,
            adjustmentAmt: totals.grandTotal - totals.occurrenceListPrice - totals.tax,
            price: totals.occurrenceListPrice + (totals.grandTotal - totals.occurrenceListPrice - totals.tax),
            tax: totals.tax,
            total: totals.grandTotal,
        });

        return { billItems, pricing };
    }

    @Timeout
    public static async getEventData(eventID: number | NumericalString, cache = false, longCache = false) {
        const includes: EventIncludeOption[] = [
            "customers",
            "reservations",
            "attributes",
            "workflow",
            "workflow_history",
            "text",
            "requirements",
            "categories",
            "relationships",
        ];

        const FLS = await FlsService.getFls();
        const billing =
            FLS.EVENT_BILLING === AccessLevels.None ? undefined : BpeService.getEventBillItems(Number(eventID));

        let eventData: ReturnType<typeof EventService.getEventInclude>;
        if (cache && longCache) eventData = EventService.getEventIncludeCachedLong(eventID as number, includes);
        else if (cache) eventData = EventService.getEventIncludeCached(eventID as number, includes);
        else eventData = EventService.getEventInclude(eventID as number, includes);

        return Promise.all([billing, eventData]).then(([billing, eventData]) => {
            eventData.bill_items = billing?.billItems;
            eventData.pricing = billing?.pricing;
            return eventData;
        });
    }

    ////////////////////
    //source: "event-form", "express",
    @Timeout
    public static async bpeCreateToDo(scenarios: EmailScenario[], variables: BpeVarsI, eventData: any) {
        if (!eventData.todo && !scenarios.length) return; // new event haven't create toDo yet
        let eventId = parseInt(S25Util.propertyGetVal(eventData, "event_id"));

        const fls = await FlsService.getFls();

        if (!hasFullFls(fls.CREATE_TODO)) return;

        for (const scenario of scenarios) {
            for (const template of scenario.todoTemplates) {
                let templateCode = template.mode === "code" ? template.code : BpeUtil.todoTemplateToCode(template);
                variables.template = template.itemName;
                let finalTemplateCode = `{${BpeUtil.interpolateString(variables, templateCode)}}`;
                const todo = BpeService.executeTodoTemplate(finalTemplateCode, variables, eventData);
                /******* check todo has been create or not *** */
                let taskName: any = todo.taskName;
                let assignTo: number = parseInt(todo.assignTo);

                if (eventData?.todo) {
                    const exists = eventData?.todo.some(
                        (task: any) => task?.todo_name === taskName && task.cur_assigned_to_id === assignTo,
                    );
                    if (exists) continue;
                }

                let currentTime = "T" + S25Util.date.hourMinuteString(new Date());
                let firstDate: any = new Date(eventData.start_date + currentTime);

                firstDate =
                    S25Datefilter.transform(
                        firstDate.setDate(firstDate.getDate() + parseInt(todo.dueDate)),
                        "yyyy-MM-dd",
                    ) + currentTime;

                let item = {
                    taskName: todo.taskName,
                    eventId: eventId,
                    taskComment: todo.comment,
                    dueDate: firstDate,
                    assignedById: todo.assignBy,
                    assignedToId: todo.assignTo,
                };

                TaskService.createEventTodo(item, String(template.itemName));
            }
        }
    }

    public static executeEmailTemplate(templateCode: string, vars: any, eventData: any): EmailClass {
        const email = ExecutionEnvironment.run(templateCode, {
            vars,
            eventData: eventData,
            util: BpeUtil.util,
        });
        email.reports =
            email?.reports
                ?.split(",")
                .map(S25Util.trim)
                .filter((r: unknown) => r) || [];
        return email;
    }

    public static executeTodoTemplate(templateCode: string, vars: any, eventData: any): TodoClass {
        const todo = ExecutionEnvironment.run(templateCode, {
            vars,
            eventData: eventData,
            util: BpeUtil.util,
        });
        return todo;
    }

    public static async populateDMReports(data: {
        reports: Report.Object[];
        contracts: DocumentListItem[];
        email: EmailClass;
        eventId: number;
    }) {
        const { reports, contracts, email, eventId } = data;
        if (!reports) return;

        const documentReportPromises: Promise<EmailReport>[] = [];
        const reportsById = new Map(reports.map((report) => [Number(report.report_id), report]));
        const documentsByReportId = new Map(contracts.map((doc) => [doc.reportId, doc]));
        for (const reportId of email.reports) {
            const isString = reportId === "confirmation" || reportId === "invoice";
            const report = !isString && reportsById.get(Number(reportId));

            if (report && report.report_engine === "DM") {
                const document = documentsByReportId.get(Number(report.report_id));
                if (!document) continue;

                const idPromise = DocumentService.getEventDocumentRunId(document.itemId, eventId);
                const docPromise: Promise<EmailReport> = idPromise.then((id) => ({
                    itemID: id,
                    rpt_id: reportId as string,
                    rpt_engine: "DM",
                    isString: false,
                    objString: "",
                    fromTemplate: true,
                }));
                documentReportPromises.push(docPromise);
            } else {
                documentReportPromises.push(
                    Promise.resolve({
                        itemID: null,
                        rpt_id: isString ? null : parseInt(reportId as string),
                        rpt_engine: "",
                        isString: isString,
                        objString: reportId as string,
                        fromTemplate: true,
                    }),
                );
            }
        }

        email.reports = await Promise.all(documentReportPromises);
    }
}

function findTodos(eventData: any) {
    let todos = S25Util.propertyGet(eventData, "todo") || [];
    todos = S25Util.array.forceArray(todos);
    todos = todos.filter((todo: any) => {
        return todo.todo_subtype === 99;
    });

    return todos;
}

export interface EmailFullScenariosResponse {
    root: {
        scenarios: EmailScenario[];
    };
}

export interface EmailScenario {
    itemId: number;
    itemName: string;
    mode: "form" | "code" | "hybridForm" | "hybridCode";
    code: string;
    isActive: NumericalBoolean;
    templates?: EmailTemplate[];
    createToDo?: NumericalBoolean;
    todoTemplates?: ToDoTemplate[];
    codeData?: {
        on: "all" | "edit" | "create";
        sources: {
            task?: boolean;
            "event-form"?: boolean;
            express?: boolean;
            "event-state-change"?: boolean;
            cancelTodos?: boolean;
        };
        taskTriggers: { assignments: boolean; approvals: boolean; fyis: boolean };
        pre: ScenarioCriteria;
        post: ScenarioCriteria;
    };
    // Below properties are no longer used. This data is now stored in code
    onAction: "all" | "edit" | "create";
    inclLocPref: NumericalBoolean;
    inclResPref: NumericalBoolean;
    onEventStateChangeAction: NumericalBoolean;
    onEventFormAction: NumericalBoolean;
    onTaskAction: NumericalBoolean;
    onTodoAction: NumericalBoolean;
    onExpressAction: NumericalBoolean;
    eventStates?: ScenarioItem[];
    resources?: ScenarioItem[];
    eventTypes?: ScenarioItem[];
    locations?: ScenarioItem[];
    requirements?: ScenarioItem[];
    preEventStates?: ScenarioItem[];
    organizations?: ScenarioItem[];
    securityGroups?: ScenarioItem[];
    attributes?: ScenarioAttributeItem[];
    allApDone?: NumericalBoolean;
    allNpDone?: NumericalBoolean;
    allFyiDone?: NumericalBoolean;
    isScheduled?: NumericalBoolean;
    scheduleType?: "daysFromEventStart" | "daysFromEventEnd" | "daysFromNow";
    scheduleDays?: number;
}

export type ScenarioCriteria = {
    usePrefLocations?: boolean;
    usePrefResources?: boolean;
    states: ScenarioIncludeExclude<ScenarioItem>;
    types: ScenarioIncludeExclude<ScenarioItem>;
    locations: ScenarioIncludeExclude<ScenarioItem>;
    resources: ScenarioIncludeExclude<ScenarioItem>;
    primaryOrgs: ScenarioIncludeExclude<ScenarioItem>;
    requirements: ScenarioIncludeExclude<ScenarioItem>;
    securityGroups: ScenarioIncludeExclude<ScenarioItem>;
    customAttributes: ScenarioIncludeExclude<ScenarioAttributeItem>;
    expectedHeadcount: { operator: string; value?: number; value2?: number };
};

type ScenarioIncludeExclude<T extends ScenarioItem | ScenarioAttributeItem> = {
    include: T[];
    exclude: T[];
};

export interface ScenarioItem {
    itemId: number;
    itemName: string;
}

export interface ScenarioAttributeItem {
    itemId: number;
    itemName: string;
    itemValue: any;
    additionalInfo: string;
    custAtrbType: Rules.AttributeType;
    operator: Rules.Operator;
}

export interface EmailFullTemplatesResponse {
    root: {
        templates: EmailTemplate[];
    };
}
export interface ToDoFullTemplatesResponse {
    root: {
        templates: ToDoTemplate[];
    };
}

export interface BPETemplate {
    mode: "form" | "code";
    itemId: number;
    itemName?: string | number;
    code: string;
    comment: string;
    isToDoTemplate?: NumericalBoolean;
    tags?: MdTag[];
}

export interface EmailTemplate extends BPETemplate {
    mode: "form" | "code";
    cc: string;
    itemId: number;
    reports: string; // Comma separated string
    itemName: string | number;
    bcc: string;
    code: string;
    subject: string;
    isManual: NumericalBoolean;
    to: string;
    body: string;
    from?: string;
    isToDoTemplate?: NumericalBoolean;
    iCalFile?: NumericalBoolean;
    tags?: MdTag[];
}

export interface ToDoTemplate extends BPETemplate {
    mode: "form" | "code";
    itemId: number;
    itemName?: string | number;
    code: string;
    comment: string;
    assignBy: any;
    assignTo: any;
    dueDate?: number;
    isToDoTemplate?: NumericalBoolean;
    taskName?: string;
    multiSelectBeanAssignTo?: any;
    multiSelectBeanAssignBy?: any;
}

export type BpeBillItem = Partial<Total & LineItem & Adjustment> & {
    rateGroupName?: string;
    rateScheduleName?: string;
};

type BpeSendEmailData = {
    eventData: any;
    eventId: number;
    variables: BpeVarsI;
    fls: Fls;
    sessionId: string;
    reports: Report.Object[];
    contracts: DocumentListItem[];
};
